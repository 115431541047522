import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Aumenta la base de clientes recurrentes y puntuales.</p>

                            <ul className="social-link">
                                <li>
                                    <Link to="https://www.linkedin.com/company/yftel/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explora</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Inicio
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/yftel-nosotros">
                                        Nosotros
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/detalles-caso-estudio1">
                                        Casos de Estudio
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/yftel-contacto">
                                        Contacto
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Recursos</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/yftel-control-acceso">
                                        Control de Acceso
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/yftel-retencion-clientes">
                                        Retención de Clientes
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/yftel-portal-wifi">
                                        Portal Wifi
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Dirección</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Avenida Juan de Aliaga 426 Dpto 101, Magdalena del Mar, Lima, <br /> Perú
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+51970697278">+51 (970) 697 278</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:corporativo@yftelperu.com">corporativo@yftelperu.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>YFTEL</strong> Todos Los Derechos Reservados <a target="_blank" href="https://www.yftelperu.com/" rel="noreferrer">YFTEL</a></p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Politica de Privacidad
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terminos & Condiciones
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
