import React from 'react'
import Layout from "../components/App/YftelLayout"
import Navbar from "../components/App/YftelNavbar2"
import PageBanner from '../components/Common/YftelPageBanner'
import Footer from "../components/App/YftelFooter"
import ContactInfo from '../components/YftelContact/ContactInfo'
import ContactForm from '../components/YftelContact/ContactForm'
const Contact = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Contacto" 
                homePageText="Inicio" 
                homePageUrl="/" 
                activePageText="Contacto" 
            />
            <ContactInfo />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Contact
